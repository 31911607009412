<template>
  <b-row>
   <b-row>
      <b-col lg="12" sm="12">
        <b-row>
          <b-col lg="12" sm="12">
            <b-overlay :show="loadings">
              <b-button @click="pdfExport" variant="primary" class="btn-add">
                {{ $t('globalTrans.print') }}
              </b-button>
              <!-- <b-row>
              <b-button class="btn-add" variant="primary" @click="pdfExport">
                  {{ $t('globalTrans.print') }}
              </b-button>
            </b-row> -->
              <b-row class="m-4">
                <b-col md="6" lg="6"> {{ memo_no }}</b-col>
                <b-col md="6" lg="6" class="text-right" v-if="detailsData"> {{ $t('globalTrans.date') }}- {{ todayDate() }}. </b-col>
              </b-row>
              <b-row>
                <b-col md="12 text-center">
                  <h5 v-if="meeting_date">
                    {{ meeting_title_en }} <br />
                    {{ venue_en }} <br />
                    {{ $t('globalTrans.date') }}: {{ formatted(meeting_date) }}
                  </h5>
                </b-col>
                <b-col lg="12" sm="12" class="m-4"  v-if="detailsData">
                  <div>
                    <p class="text-weight-bold"> {{ $t('meetingManagement.agenda') }}: <br /> </p>
                    <p class="text-dark pl-4" v-html="agenda_text_en"></p>
                    <!-- <b-col md="12 text-center">
                      <span class="badge badge-primary card-title"><h5>{{ $t('globalTrans.details') }}</h5></span>
                    </b-col> -->
                    <b-table-simple hover small caption-top responsive striped bordered>
                      <b-tr>
                        <b-th>{{ $t('meetingManagement.item') }}</b-th>
                        <b-th>{{ $t('meetingManagement.decision') }}</b-th>
                        <b-th>{{ $t('meetingManagement.update') }}</b-th>
                        <b-th>{{ $t('globalTrans.designation') }}</b-th>
                        <b-th>{{ $t('stakeholder.employee_name') }}</b-th>
                      </b-tr>
                      <template v-for="(head, index) in detailsData.heads">
                        <slot v-for="(detail, index1) in head.details">
                          <b-tr v-if="index1 === 0" :key="index">
                            <b-td colspan="5"><h5>{{ head.head_title_en }}</h5></b-td>
                          </b-tr>
                          <b-tr>
                            <b-td> {{ detail.item }} </b-td>
                            <b-td> {{ detail.decision }} </b-td>
                            <b-td> {{ detail.update }} </b-td>
                            <b-td> {{ getDesignationList(detail.designation_id) }} </b-td>
                            <b-td> {{ getEmployeeName(detail.employee_user_id) }} </b-td>
                          </b-tr>
                        </slot>
                      </template>
                    </b-table-simple>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-row>
</template>
<script>
import RestApi, { authServiceBaseUrl, bftiResReportServiceBaseUrl } from '@/config/api_config'
import { userListByDesignWise, detailsDataApi } from '../../api/routes'
import ExportPdf from './export_pdf_details'

export default {
  name: 'Details',
  props: ['id', 'item'],
  data () {
    return {
      errors: [],
      detailsData: {},
      officer: {},
      memo_no: '',
      venue_en: '',
      meeting_title_en: '',
      meeting_date: '',
      agenda_text_en: '',
      loadings: true
    }
  },
  created () {
    this.getAllUserList()
  },
  computed: {
  },
  methods: {
    getAllUserList () {
      RestApi.getData(authServiceBaseUrl, userListByDesignWise).then(response => {
        if (response.success) {
          this.allUserList = response.data
          if (this.item) {
            const tmp = this.DetailsData()
            this.detailsData = tmp
          }
        }
      })
    },
    getDetailsData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    DetailsData () {
      // this.loading = true
      const params = Object.assign({}, { id: this.item.id })
      RestApi.getData(bftiResReportServiceBaseUrl, detailsDataApi, params).then(response => {
        if (response.success) {
          this.detailsData = response.data
          this.memo_no = this.detailsData.memo_no
          this.meeting_title_en = this.detailsData.meeting_title_en
          this.venue_en = this.detailsData.venue_en
          this.meeting_date = this.detailsData.meeting_date
          this.agenda_text_en = this.detailsData.agenda_text_en
          this.loadings = false
        } else {
          this.loadings = false
        }
      })
    },
    findUser (desigId, userId) {
      const params = Object.assign({}, { designation_id: desigId })
      RestApi.getData(authServiceBaseUrl, userListByDesignWise, params).then(response => {
        if (response.success) {
          this.officer = response.data.find(user => user.id === userId)
        }
      })
    },
    getDesignationList (id) {
      const data = this.$store.state.CommonService.commonObj.designationList.find(desig => desig.value === parseInt(id))
      return data !== 'undefined' ? data.text : ''
    },
    pdfExport () {
      const reportTitle = this.$t('meetingManagement.meeting_information') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(bftiResReportServiceBaseUrl, '/configuration/report-head/detail', 10, reportTitle, this, this.detailsData, this.todayDate(), this.formatted(this.detailsData.meeting_date))
    },
    formatted (value) {
      return new Date(value).toLocaleString(this.$i18n.locale + '-GB', {
        day: 'numeric', // numeric, 2-digit
        year: 'numeric', // numeric, 2-digit
        month: 'long' // numeric, 2-digit, long, short, narrow
      })
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.toString().replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.toString().replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    todayDate () {
      const today = new Date().toISOString().slice(0, 10)
      return this.formatted(today)
    },
    getEmployeeName (empID) {
      const empData = this.allUserList.find(user => user.id === empID)
      if (empData) {
        return empData.name
      }
    }
    // getDesignationList (id) {
    //   const data = this.$store.state.CommonService.commonObj.designationList.find(desig => desig.value === parseInt(id))
    //   return data !== 'undefined' ? data.text : ''
    // }
  }
}
</script>
